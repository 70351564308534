exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tag-list-js": () => import("./../../../src/pages/tag-list.js" /* webpackChunkName: "component---src-pages-tag-list-js" */),
  "component---src-pages-university-admission-jsx": () => import("./../../../src/pages/university-admission.jsx" /* webpackChunkName: "component---src-pages-university-admission-jsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tags-jsx": () => import("./../../../src/templates/tags.jsx" /* webpackChunkName: "component---src-templates-tags-jsx" */)
}

